export default {
  siteTitle: 'Get bonus',
  blogerName: 'QASHBACK',
  socialsList: [
    {
      name: 'telegram',
      url: 'https://t.me/qashbackoficial'
    },
    {
      name: 'youtube',
      url: 'https://www.youtube.com/@qashbackoficial'
    },
    {
      name: 'youtube',
      url: 'https://www.youtube.com/@qashbackoficial'
    },
    {
      name: 'youtube',
      url: 'https://www.youtube.com/@vitin_qashback'
    },
    {
      name: 'youtube',
      url: 'https://www.youtube.com/@swift_qashback'
    },
    {
      name: 'youtube',
      url: 'https://www.youtube.com/@kmarguin_qashback'
    }
  ],
  projects: [
    {
      name: 'lex',
      url: 'https://lex-irrs01.com/cf1b05fb1',
      gameTitle: 'Book of Kemet',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: '1go',
      url: 'https://1go-irrs.com/cf237d4cc',
      gameTitle: 'Book of Kemet (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'monro',
      url: 'https://mnr-blrs.com/c5f9adc7f',
      gameTitle: 'Burning Chilli X (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'drip',
      url: 'https://drp-irrs12.com/c401c7bf2',
      gameTitle: 'Alien Fruits (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'starda',
      url: 'https://strd-irrs12.com/cf75f3e6e',
      gameTitle: 'Starda Queen (BGaming)',
    },
    {
      name: 'legzo',
      url: 'https://gzo-irrs01.com/c34a2038f',
      gameTitle: 'Legzo Punk (BGaming)',
    },
    {
      name: 'fresh',
      url: 'https://fresh-sfgjhyrt02.com/c56fc98c1',
      gameTitle: 'Doors Of Fresh (BGaming)',
    },
    {
      name: 'izzi',
      url: 'https://izzi-irrs01.com/c4526f0a2',
      gameTitle: 'Izzi Art (BGaming)',
    },
    {
      name: 'sol',
      url: 'https://sol-diamew02.com/c2e732b94',
      gameTitle: 'Doors Of Sol (BGaming)',
    },
    {
      name: 'jet',
      url: 'https://jtfr-itsokarsn.com/cf0d0d5e4',
      gameTitle: 'JET AIR (BGaming)',
    }
  ],
  headerText: 'Cadastre-se com um código promocional <strong>QASHBACK</strong> e receba 50 rodadas grátis nos jogos abaixo',
  yourPromocode: 'Seu código promocional',
  gameBonus: 'Bónus no jogo',
  promocode: 'QASHBACK',
  casinoBonus1: '<strong>100%</strong><div>No primeiro depósito</div>',
  casinoBonus2: '<strong>50FS</strong><div>Para cadastro com código promocional</div>',
  firstDeposit: 'DE PRIMEIRO DEPÓSITO',
  copyMessage: 'Código promocional copiado',
  theme: 'dark'
};
